import { clearUser } from 'store/userAuthSlice';
import useKakaoLogin from 'hooks/UseKakaoLogin';
import HeaderTitle from 'component/Header/HeaderTitle';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserAPI from '../../api/userAPI';

const MyPage = () => {
  const kakaoLogin = useKakaoLogin();
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.userAuth.accessToken);
  const { data: meData } = UserAPI.useGetMe({ accessToken });
  const onLogout = () => {
    dispatch(clearUser());
    kakaoLogin.logout();
  };

  return (
    <>
      <HeaderTitle title="내 정보" />
      <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0 h-full">
        <div className="flex flex-col justify-center items-center h-52 gap-2">
          <img
            className="w-16"
            src="/imgs/graphic/graphic-person.svg"
            alt="프로필 이미지"
          />
          <h3 className="text-black text-xl font-bold">
            {meData?.data?.name ?? '사용자'} 님
          </h3>
          <h6 className="text-xs">{meData?.data?.point ?? 0} 포인트</h6>
        </div>
        <div className="border-t border-solid border-zinc-400 mt-4">
          <ul>
            {meData?.data?.masterGyms?.length > 0 && (
              <li>
                <Link to="/mypage/gymmanage">
                  <div className="h-16 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative">
                    <h4 className="text-sm font-bold text-black">
                      내 체육관 관리
                    </h4>
                    <p className="text-xs text-gray-500">
                      {meData.data.masterGyms[0].name}
                    </p>
                    <img
                      src="/imgs/ic/arrow-right.svg"
                      className="w-4 h-4 absolute right-0"
                      alt="화살표"
                    />
                  </div>
                </Link>
              </li>
            )}
            <li>
              <Link to="/mypage/chargecash">
                <div className="h-16 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative">
                  <h4 className="text-sm font-bold text-black">
                    캐시 충전하기
                  </h4>
                  <img
                    src="/imgs/ic/arrow-right.svg"
                    className="w-4 h-4 absolute right-0"
                    alt="화살표"
                  />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/mypage/cashhistory">
                <div className="h-16 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative">
                  <h4 className="text-sm font-bold text-black">
                    캐시 사용/충전내역
                  </h4>
                  <img
                    src="/imgs/ic/arrow-right.svg"
                    className="w-4 h-4 absolute right-0"
                    alt="화살표"
                  />
                </div>
              </Link>
            </li>
            <li>
              <a
                href="https://pf.kakao.com/_xgKMxixj"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="h-16 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative">
                  <h4 className="text-sm font-bold text-black">
                    고객센터 (카카오채널)
                  </h4>
                  <img
                    src="/imgs/ic/arrow-right.svg"
                    className="w-4 h-4 absolute right-0 -rotate-45"
                    alt="화살표"
                  />
                </div>
              </a>
            </li>
            <li>
              <div
                onClick={onLogout}
                className="h-16 w-full border-b border-solid border-zinc-400 flex flex-col justify-center gap-1 relative cursor-pointer"
              >
                <h4 className="text-sm font-bold text-black">로그아웃</h4>
                <img
                  src="/imgs/ic/arrow-right.svg"
                  className="w-4 h-4 absolute right-0 -rotate-45"
                  alt="화살표"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MyPage;
