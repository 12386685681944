import React from 'react';
import HeaderTitle from 'component/Header/HeaderTitle';

const CASH_AMOUNTS = [1000, 5000, 10000, 20000, 30000, 40000, 50000, 100000];

const ChargeCash = () => {
  return (
    <>
      <HeaderTitle title="캐시 충전하기" />
      <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0">
        <p className="text-gray-500 text-sm mt-2">
          각 캐시는 현금과 1:1로 대응됩니다.
        </p>

        <div className="mt-8 flex flex-col gap-4">
          {CASH_AMOUNTS.map(amount => (
            <button
              key={amount}
              className="w-full h-14 border border-gray-300 rounded-lg hover:border-orange-500 hover:bg-orange-50 transition-colors flex justify-between items-center px-4"
              onClick={() => {
                // TODO: 결제 처리
              }}
            >
              <span className="text-base font-medium">
                {amount.toLocaleString()} 캐시
              </span>
              <span className="text-sm text-gray-500">
                {amount.toLocaleString()}원
              </span>
            </button>
          ))}
        </div>

        <div className="mt-6 p-4 bg-gray-100 rounded-lg">
          <p className="text-sm text-gray-600">
            • 충전된 캐시는 즉시 반영됩니다.
            <br />
            • 결제는 현금으로만 가능합니다.
            <br />• 충전 후 1시간 이내에 사용 가능합니다.
          </p>
        </div>
      </div>
    </>
  );
};

export default ChargeCash;
