import { API } from 'api/API';
import EditGymLayout from 'layout/Admin/EditGymLayout';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EditGymCourseModal from './EditGymCourseModal';
import { courseValidator, validateChecker } from 'utils/validator';
import { useSelector } from 'react-redux';

const defaultCourse = {
  day: [0, 0, 0, 0, 0, 0, 0],
  start: '',
  time: {
    hour: 0,
    minute: 0,
  },
  maxPeople: 0,
  offlinePeople: 0,
  name: '',
  open: false,
  courseId: '',
  onSubmit: () => {},
};

const dayName = ['월', '화', '수', '목', '금', '토', '일'];

const timeFormat = time => {
  const hour = parseInt(time / 60);
  const minute = parseInt(time % 60);
  let result = `${hour}시간`;
  if (minute > 0) {
    result += ` ${minute}분`;
  }
  return result;
};

const EditGymCourse = () => {
  const { accessToken } = useSelector(state => state.adminAuth);
  const { gymid } = useParams();
  const navigate = useNavigate();

  const courseState = useState({ ...defaultCourse });
  const [, setCourseModal] = courseState;

  const { data: gymData, refetch: refetchGym } = API.useGetGymDetails({
    gymId: gymid,
  });
  const { mutateAsync: createCourse } = API.useCreateGymCourse();
  const { mutateAsync: updateCourse } = API.useUpdateGymCourse();
  const { mutateAsync: deleteCourse } = API.useDeleteGymCourse();

  const onCreate = course => {
    const result = validateChecker(course, courseValidator);
    if (!result.success) {
      alert(result.message);
      return;
    }
    createCourse({
      accessToken,
      gymId: gymid,
      start: course.start,
      day: course.day.join(''),
      maxPeople: parseInt(course.maxPeople),
      offlinePeople: parseInt(course.offlinePeople),
      name: course.name,
      time: parseInt(course.time.hour * 60 + course.time.minute),
    }).then(res => {
      if (res.success) {
        refetchGym();
        setCourseModal({ ...defaultCourse });
        alert('강좌 추가에 성공했습니다.');
      } else {
        alert('강좌 추가에 실패했습니다.');
      }
    });
  };

  const setCreateCourse = () => {
    setCourseModal({
      ...defaultCourse,
      day: [...defaultCourse.day],
      time: {
        ...defaultCourse.time,
      },
      open: true,
      onSubmit: onCreate,
    });
  };

  const setUpdateCourse = course => {
    setCourseModal({
      day: course.day.split('').map(v => parseInt(v)),
      start: course.start,
      time: {
        hour: parseInt(course.time / 60),
        minute: parseInt(course.time % 60),
      },
      maxPeople: course.maxPeople,
      offlinePeople: course.offlinePeople,
      name: course.name,
      open: true,
      courseId: course._id,
      onSubmit: onUpdate,
    });
  };

  const onUpdate = course => {
    const result = validateChecker(course, courseValidator);
    if (!result.success) {
      alert(result.message);
      return;
    }
    updateCourse({
      accessToken,
      courseId: course.courseId,
      start: course.start,
      day: course.day.join(''),
      maxPeople: parseInt(course.maxPeople),
      offlinePeople: parseInt(course.offlinePeople),
      name: course.name,
      time: parseInt(course.time.hour * 60 + course.time.minute),
    }).then(res => {
      if (res.success) {
        refetchGym();
        setCourseModal({ ...defaultCourse });
        alert('강좌 수정에 성공했습니다.');
      } else {
        alert('강좌 수정에 실패했습니다.');
      }
    });
  };

  const onDelete = courseId => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      deleteCourse({
        accessToken,
        courseId,
      }).then(res => {
        if (res.success) {
          refetchGym();
          alert('강좌 삭제에 성공했습니다.');
        } else {
          alert('강좌 삭제에 실패했습니다.');
        }
      });
    }
  };

  useEffect(() => {
    if (gymData) {
      if (!gymData?.success) {
        alert('잘못된 접근입니다.');
        navigate(-1);
      }
    }
  }, [gymData, navigate]);

  if (!gymData?.success) return null;
  return (
    <EditGymLayout gym={gymData.data}>
      <div className="p-10 flex flex-col">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">
            * 체육관 강좌 수정
          </p>
        </div>
        <div className="flex flex-col">
          <div className="flex mb-2">
            <p className="text-2xl text-gray-500 font-semibold">- 강좌 목록</p>
            <button
              onClick={setCreateCourse}
              className="ml-5 bg-skyblue2 text-white rounded-xl px-2 py-1"
            >
              강좌 추가
            </button>
          </div>
          <table className="table-fixed w-[40rem]">
            <thead>
              <tr className="border-2 border-gray-300">
                <th className="text-xl text-center w-60">이름</th>
                <th className="text-xl text-center w-48">인원</th>
                <th className="text-xl text-center w-60">요일</th>
                <th className="text-xl text-center w-60">시간</th>
                <th className="text-xl text-cente w-48" />
              </tr>
            </thead>
            <tbody>
              <tr className="h-2" />
              {gymData?.data.courses.map(course => (
                <tr key={course._id} className="border-2 border-gray-300 h-16">
                  <td className="text-xl text-center">{course.name}</td>
                  <td className="text-xl text-center">{`${
                    course.offlinePeople + course.onlinePeople
                  } / ${course.maxPeople}`}</td>
                  <td className="text-xl text-center">
                    {course.day
                      .split('')
                      .map((v, i) => (v === '1' ? `${dayName[i]} ` : ''))}
                  </td>
                  <td className="text-xl text-center">{`${
                    course.start
                  } (${timeFormat(course.time)})`}</td>
                  <td className="text-xl text-center flex w-48 h-16 place-content-center place-items-center">
                    <button
                      onClick={() => setUpdateCourse(course)}
                      className="bg-skyblue2 text-white h-10 rounded-xl w-20"
                    >
                      수정
                    </button>
                    <button
                      onClick={() => onDelete(course._id)}
                      className="bg-red1 text-white h-10 rounded-xl w-20 ml-5"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <EditGymCourseModal
        modalState={courseState}
        defaultCourse={defaultCourse}
      />
    </EditGymLayout>
  );
};

export default EditGymCourse;
