import React, { useEffect, useState } from 'react';
import SlideView from '../../component/SlideView/SlideView';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import Modal from '../../component/Modal/Modal';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../api/API';
import { useSelector } from 'react-redux';

const SlideItem = ({ link, image, _id }) => {
  if (link)
    return (
      <a key={_id} href={link} className="w-full max-w-[40rem] m-auto flex">
        <img
          alt="slide item"
          src={image}
          className="w-full h-full object-contain"
        />
      </a>
    );
  else
    return (
      <div key={_id} className="w-full max-w-[40rem] m-auto flex">
        <img
          alt="slide item"
          src={image}
          className="w-full h-full object-contain"
        />
      </div>
    );
};

const dayName = ['월', '화', '수', '목', '금', '토', '일'];

const Info = () => {
  const modalState = useState(false);
  const [, setModal] = modalState;
  const { id } = useParams();
  const { accessToken } = useSelector(state => state.userAuth);
  const [gym, setGym] = useState();
  const { data: gymData } = API.useGetGymDetails({
    gymId: id,
    accessToken: accessToken,
  });
  useEffect(() => {
    setModal(gym?.showNotice);
  }, [setModal, gym]);

  useEffect(() => {
    if (gymData?.success) {
      setGym(gymData?.data);
    }
  }, [gymData]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <div className="w-full">
        <SlideView renderer={SlideItem} data={gym?.banners || []} dotSize={4} />
      </div>
      <div className="w-full max-w-[40rem] px-5 mx-auto bg-white -mt-2 rounded-t-3xl shadow-sm flex flex-col flex-grow pt-6">
        {gym?.showNotice && (
          <button
            className="mb-2 px-4 py-3 bg-yellow-50 hover:bg-yellow-100 transition-colors rounded-xl text-sm flex items-center justify-center gap-2 w-full"
            onClick={() => setModal(true)}
          >
            <div className="w-5 h-5 flex items-center justify-center">
              <img
                src="/imgs/ic/ic-notice.svg"
                alt="공지"
                className="w-5 h-5"
              />
            </div>
            <span className="font-medium text-orange-600">공지사항 보기</span>
          </button>
        )}

        <div className="flex justify-between items-start gap-4 mt-6">
          <div className="flex-1 min-w-0">
            <h1 className="text-2xl font-bold text-gray-900 mb-3 break-words">
              {gym?.name}
            </h1>
            <div className="flex flex-col gap-3">
              <p className="text-gray-600 text-base flex items-start gap-2">
                <img
                  src="/imgs/ic/ic-location.svg"
                  alt="위치"
                  className="w-4 h-4 flex-shrink-0 mt-1"
                />
                <span className="break-words">{gym?.address}</span>
              </p>
              <section className="bg-gray-50 p-3 rounded-lg">
                <p className="text-gray-700 text-base break-words whitespace-pre-wrap leading-relaxed">
                  {gym?.description}
                </p>
              </section>
              <p className="text-orange-600 text-lg font-bold flex items-center gap-2">
                <img
                  src="/imgs/ic/ic-point.svg"
                  alt="포인트"
                  className="w-5 h-5 flex-shrink-0"
                />
                <span className="break-keep">
                  {gym?.dayPoint + gym?.gymFee} 캐쉬/1회
                </span>
              </p>
            </div>
          </div>
          <Link to={`/reservation/${gym?._id}`} className="flex-shrink-0">
            <button className="w-[108px] h-11 bg-orange-600 hover:bg-orange-700 transition-colors rounded-xl text-white flex items-center justify-center gap-1.5 shadow-sm whitespace-nowrap">
              <img
                src="/imgs/ic/ic-time.svg"
                alt="시계"
                className="w-5 h-5 flex-shrink-0"
              />
              <span className="font-medium">예약하기</span>
            </button>
          </Link>
        </div>

        <div className="mt-8 space-y-6">
          {gym?.supplies && (
            <section className="bg-gray-50 p-4 rounded-xl">
              <h2 className="text-base font-bold text-gray-900 mb-3 flex items-center gap-2">
                <img
                  src="/imgs/ic/ic-supplies.svg"
                  alt="준비물"
                  className="w-4 h-4 flex-shrink-0"
                />
                준비물
              </h2>
              <p className="text-gray-700 text-base break-words whitespace-pre-wrap">
                {gym.supplies}
              </p>
            </section>
          )}

          <section className="bg-gray-50 p-4 rounded-xl">
            <h2 className="text-base font-bold text-gray-900 mb-3 flex items-center gap-2">
              <img src="/imgs/ic/ic-clock.svg" alt="시계" className="w-4 h-4" />
              영업시간
            </h2>
            <div className="text-gray-700 space-y-1">
              {gym?.openDays?.map((day, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span className="font-medium">{dayName[day.dayOfWeek]}</span>
                  <span>
                    {day.openTime} - {day.closeTime}
                  </span>
                </div>
              ))}
            </div>
          </section>

          <section className="bg-gray-50 p-4 rounded-xl">
            <h2 className="text-base font-bold text-gray-900 mb-3 flex items-center gap-2">
              <img src="/imgs/ic/ic-phone.svg" alt="전화" className="w-4 h-4" />
              연락처
            </h2>
            <a
              href={`tel:${gym?.phoneNumber}`}
              className="text-blue-600 hover:underline"
            >
              {gym?.phoneNumber}
            </a>
          </section>

          <section className="bg-gray-50 p-4 rounded-xl">
            <h2 className="text-base font-bold text-gray-900 mb-3 flex items-center gap-2">
              <img
                src="/imgs/ic/ic-location.svg"
                alt="위치"
                className="w-4 h-4 flex-shrink-0"
              />
              오시는 길
            </h2>
            <p className="text-gray-700 mb-4 break-words">{gym?.address}</p>
            {gym && (
              <Map
                center={{ lat: gym?.latitude, lng: gym?.longitude }}
                className="w-full aspect-square rounded-lg border border-gray-200"
              >
                <MapMarker
                  position={{ lat: gym?.latitude, lng: gym?.longitude }}
                />
              </Map>
            )}
          </section>
        </div>
      </div>

      {gym?.showNotice && (
        <Modal modalState={modalState}>
          <div className="max-w-lg w-full mx-auto p-6 bg-white rounded-2xl">
            <div className="flex items-center gap-2 mb-4">
              <div className="w-8 h-8 bg-yellow-100 rounded-full flex items-center justify-center flex-shrink-0">
                <img
                  src="/imgs/ic/ic-notice.svg"
                  alt="공지"
                  className="w-5 h-5"
                />
              </div>
              <h2 className="text-xl font-bold text-gray-900">
                {gym.notice.title}
              </h2>
            </div>
            <div className="bg-gray-50 p-4 rounded-xl">
              <p className="text-gray-700 text-base whitespace-pre-line leading-relaxed">
                {gym.notice.content}
              </p>
            </div>
            <button
              onClick={() => setModal(false)}
              className="w-full mt-4 py-3 bg-orange-600 hover:bg-orange-700 text-white font-medium rounded-xl transition-colors"
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Info;
