import React, { useState } from 'react';
import HeaderTitle from 'component/Header/HeaderTitle';
import { useSelector } from 'react-redux';
import UserAPI from '../../api/userAPI';

const CashHistory = () => {
  const [activeTab, setActiveTab] = useState('use'); // 'use' or 'charge'
  const { accessToken } = useSelector(state => state.userAuth);
  const { data: meData } = UserAPI.useGetMe({ accessToken });

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\. /g, '.')
      .slice(0, -1);
  };

  // 거래 내역 필터링
  const filteredTransactions = meData?.data?.transactions?.filter(
    transaction => {
      if (activeTab === 'use') {
        return transaction.type !== 'admin_deposit'; // 사용 내역
      } else {
        return transaction.type === 'admin_deposit'; // 충전 내역
      }
    },
  );

  return (
    <>
      <HeaderTitle title="캐시 내역" />
      <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0">
        <div className="flex justify-between items-center mt-4">
          <div className="flex gap-4">
            <button
              className={`text-base pb-2 ${
                activeTab === 'use'
                  ? 'font-bold text-black border-b-2 border-black'
                  : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('use')}
            >
              사용 내역
            </button>
            <button
              className={`text-base pb-2 ${
                activeTab === 'charge'
                  ? 'font-bold text-black border-b-2 border-black'
                  : 'text-gray-400'
              }`}
              onClick={() => setActiveTab('charge')}
            >
              충전 내역
            </button>
          </div>
        </div>

        <div className="mt-4">
          {filteredTransactions?.length === 0 ? (
            <div className="text-center text-gray-500 py-8">
              {activeTab === 'use' ? '사용 내역이' : '충전 내역이'} 없습니다.
            </div>
          ) : (
            filteredTransactions?.map((transaction, index) => (
              <div
                key={index}
                className="py-4 border-b border-gray-200 flex justify-between items-center"
              >
                <div>
                  <p className="text-gray-400 text-sm">
                    {formatDate(transaction.createdAt)}
                  </p>
                  <p className="text-base font-medium mt-1">
                    {transaction.description}
                  </p>
                </div>
                <p
                  className={`text-base font-bold ${
                    transaction.type === 'admin_deposit'
                      ? 'text-blue-500'
                      : 'text-red-500'
                  }`}
                >
                  {transaction.type === 'admin_deposit' ? '+' : '-'}
                  {transaction.amount?.toLocaleString()} 캐시
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default CashHistory;
