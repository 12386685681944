import { ajax } from './API';
import { useMutation, useQuery } from 'react-query';

const useCreateReservation = () => {
  return useMutation(({ accessToken, gymId, startAt, peopleNum, courseId }) =>
    ajax({
      method: 'POST',
      url: `/gyms/${gymId}/reservations`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        startAt,
        peopleNum,
        courseId,
      },
    }),
  );
};

const useGetCancelReservations = ({ accessToken }) => {
  const options = {
    method: 'Get',
    url: '/reservations/cancels',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery('cancelReservations', () => ajax(options));
};

const useAcceptCancelReservation = () => {
  return useMutation(({ accessToken, cancelReservationId, accept }) =>
    ajax({
      method: 'PATCH',
      url: `/reservations/cancels/${cancelReservationId}/accept`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        accept,
      },
    }),
  );
};

const useGetGymReservations = ({ accessToken, gymId, master = true }) => {
  const options = {
    method: 'Get',
    url: `/gyms/${gymId}/reservations${master ? '/master' : ''}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return useQuery(['gymReservations', gymId], () => ajax(options));
};

const useCancelReservation = () => {
  return useMutation(({ accessToken, cancelReservationId }) =>
    ajax({
      method: 'POST',
      url: `/reservations/cancels/${cancelReservationId}/accept`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

const useRequestCancelReservation = () => {
  return useMutation(({ accessToken, reservationId }) =>
    ajax({
      method: 'PATCH',
      url: `/reservations/cancels/${reservationId}/accept`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  );
};

// 체육관 예약 취소 요청 수락/거절
const useHandleCancelRequest = () => {
  return useMutation(({ accessToken, cancelReservationId, accept }) =>
    ajax({
      method: 'PATCH',
      url: `/reservations/cancels/${cancelReservationId}/accept`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        accept,
      },
    }),
  );
};

const ReservationAPI = {
  useCreateReservation,
  useGetCancelReservations,
  useAcceptCancelReservation,
  useGetGymReservations,
  useCancelReservation,
  useRequestCancelReservation,
  useHandleCancelRequest,
};

export default ReservationAPI;
