import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlayView = autoPlay(SwipeableViews);

const SlideView = ({ renderer, data }) => {
  const [newsPage, setNewsPage] = useState(0);

  const handleChangeIndex = index => {
    setNewsPage(index);
  };

  // order 값으로 정렬
  const sortedData = [...data].sort((a, b) => a.order - b.order);

  return (
    <div className="relative">
      <AutoPlayView
        className=""
        index={newsPage}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
      >
        {sortedData.map(item => (
          <div key={item._id}>
            {renderer({
              link: item.link,
              image: item.image,
              _id: item._id,
            })}
          </div>
        ))}
      </AutoPlayView>
    </div>
  );
};

export default SlideView;
