import React, { useState } from 'react';
import { API } from 'api/API'; // API 객체를 임포트

const Notice = () => {
  const { data, isLoading, isError } = API.useGetNotions();
  const [selectedNotice, setSelectedNotice] = useState(null); // 선택된 공지사항을 저장하는 상태
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달의 열림 상태

  if (isLoading) {
    return (
      <div className="w-full max-w-[40rem] mx-auto p-5">
        {/* 스켈레톤 UI */}
        <div className="animate-pulse space-y-4">
          <div className="h-6 bg-gray-300 rounded"></div>
          <div className="h-6 bg-gray-300 rounded"></div>
          <div className="h-6 bg-gray-300 rounded"></div>
          <div className="h-6 bg-gray-300 rounded"></div>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching notices.</div>;
  }

  const notices = data.data || [];

  // 모달 닫기 함수
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNotice(null);
  };

  return (
    <div className="w-full max-w-[40rem] mx-auto p-5">
      <h1 className="text-xl font-bold mb-4">공지사항</h1>

      {notices.map(notice => (
        <div
          key={notice._id}
          className="border-b border-gray-300 py-3 cursor-pointer"
          onClick={() => {
            setSelectedNotice(notice);
            setIsModalOpen(true);
          }}
        >
          <h2 className="text-lg font-semibold">{notice.title}</h2>
          <p className="text-gray-500 text-sm">
            {new Date(notice.createdAt).toLocaleDateString()}
          </p>
        </div>
      ))}

      {/* Tailwind로 모달 창 구현 */}
      {isModalOpen && selectedNotice && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full min-h-[80vh] mx-5 transform transition-all duration-300 ease-in-out scale-100">
            <h2 className="text-xl font-bold mb-4">{selectedNotice.title}</h2>
            <p className="text-gray-700 whitespace-pre-line">
              {selectedNotice.content}
            </p>
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              &times; {/* 닫기 버튼 */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notice;
