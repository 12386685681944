import React, { useEffect, useState } from 'react';

const Modal = ({ children, modalState }) => {
  const [isOpen, setIsOpen] = modalState;
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    }
  }, [isOpen]);

  if (!isOpen && !isAnimating) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center px-4 transition-all duration-300 ${
        isOpen ? 'bg-black/30' : 'bg-black/0'
      }`}
      onClick={() => setIsOpen(false)}
      onTransitionEnd={() => {
        if (!isOpen) setIsAnimating(false);
      }}
    >
      <div className="relative" onClick={e => e.stopPropagation()}>
        <div
          className={`transform transition-all duration-300 ${
            isOpen ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
