import React, { useMemo } from 'react';

const TimeSelector = ({ reservation, setReservation, gymData }) => {
  // 선택된 날짜의 요일에 따른 영업 시간 정보 가져오기
  const operatingHours = useMemo(() => {
    if (!reservation.date || !gymData?.openDays) return null;

    const selectedDay = new Date(reservation.date).getDay(); // 0: 일요일, 1: 월요일, ...
    return gymData.openDays.find(day => day.dayOfWeek === selectedDay);
  }, [reservation.date, gymData]);

  // 영업 시간에 따라 30분 단위로 시간 슬롯 생성
  const timeSlots = useMemo(() => {
    if (!operatingHours) return { morningSlots: [], afternoonSlots: [] };

    const { openTime, closeTime } = operatingHours;
    const slots = [];

    let [openHour, openMinute] = openTime.split(':').map(Number);
    let [closeHour, closeMinute] = closeTime.split(':').map(Number);

    // 00:00은 자정을 의미하므로 24:00으로 처리
    if (closeHour === 0 && closeMinute === 0) {
      closeHour = 24;
    }

    let currentHour = openHour;
    let currentMinute = openMinute;

    // 30분 간격으로 슬롯 생성
    while (
      currentHour < closeHour ||
      (currentHour === closeHour && currentMinute <= closeMinute)
    ) {
      // 자정(24:00)은 제외
      if (!(currentHour === 24 && currentMinute === 0)) {
        const hour24 = currentHour;
        const isPM = hour24 >= 12;
        const hour12 = hour24 % 12 === 0 ? 12 : hour24 % 12;
        const formattedMinute = currentMinute === 0 ? '00' : currentMinute;
        const ampm = isPM ? '오후' : '오전';

        slots.push({
          display: `${ampm} ${hour12}:${formattedMinute}`,
          hour24,
          minute: currentMinute,
        });
      }

      // 30분 증가
      currentMinute += 30;
      if (currentMinute >= 60) {
        currentHour += 1;
        currentMinute = 0;
      }
    }

    // 오전/오후 슬롯 분류
    const morningSlots = slots.filter(time => time.hour24 < 12);
    const afternoonSlots = slots.filter(time => time.hour24 >= 12);

    return { morningSlots, afternoonSlots };
  }, [operatingHours]);

  // 시간 선택 시 날짜와 결합하여 startAt에 저장
  const handleTimeSelect = timeObj => {
    if (reservation.date) {
      const selectedDate = new Date(reservation.date); // 선택한 날짜와 결합
      selectedDate.setHours(timeObj.hour24);
      selectedDate.setMinutes(timeObj.minute);
      selectedDate.setSeconds(0);

      setReservation(prev => ({
        ...prev,
        startAt: selectedDate, // Date 객체로 저장
      }));
    } else {
      alert('먼저 날짜를 선택해 주세요.');
    }
  };

  const isSelected = timeObj => {
    if (reservation.startAt) {
      const selectedHours = reservation.startAt.getHours();
      const selectedMinutes = reservation.startAt.getMinutes();
      return (
        selectedHours === timeObj.hour24 && selectedMinutes === timeObj.minute
      );
    }
    return false;
  };

  // 영업시간 정보가 없는 경우 알림 메시지 표시
  if (!operatingHours && reservation.date) {
    return (
      <div className="time-selector p-4 text-center">
        <p className="text-lg text-gray-500">
          선택한 날짜에 대한 영업 시간 정보가 없습니다.
        </p>
      </div>
    );
  }

  // 날짜를 선택하지 않은 경우 안내 메시지 표시
  if (!reservation.date) {
    return (
      <div className="time-selector p-4 text-center">
        <p className="text-lg text-gray-500">먼저 날짜를 선택해 주세요.</p>
      </div>
    );
  }

  return (
    <div className="time-selector">
      {timeSlots.morningSlots.length > 0 && (
        <div className="mb-5">
          <p className="text-lg font-regular text-gray-500 mb-3">오전</p>
          <div className="grid grid-cols-4 gap-2">
            {timeSlots.morningSlots.map(timeObj => (
              <button
                key={`${timeObj.hour24}:${timeObj.minute}`}
                onClick={() => handleTimeSelect(timeObj)}
                className={`w-full h-10 border rounded-lg text-sm transition-colors ${
                  isSelected(timeObj)
                    ? 'bg-red-500 text-white'
                    : 'bg-white border-gray-300 text-black hover:bg-gray-200'
                }`}
              >
                {timeObj.display}
              </button>
            ))}
          </div>
        </div>
      )}

      {timeSlots.afternoonSlots.length > 0 && (
        <div className="mb-5">
          <p className="text-lg font-regular text-gray-500 mb-3">오후</p>
          <div className="grid grid-cols-4 gap-2">
            {timeSlots.afternoonSlots.map(timeObj => (
              <button
                key={`${timeObj.hour24}:${timeObj.minute}`}
                onClick={() => handleTimeSelect(timeObj)}
                className={`w-full h-10 border rounded-lg text-sm transition-colors ${
                  isSelected(timeObj)
                    ? 'bg-red-500 text-white'
                    : 'bg-white border-gray-300 text-black hover:bg-gray-200'
                }`}
              >
                {timeObj.display}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSelector;
