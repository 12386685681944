import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';

const ImageInput = ({ className, image, setImage }) => {
  const imgRef = useRef();
  const showRef = useRef();
  const [show, setShow] = useState(image);

  const saveImgFile = () => {
    const file = imgRef.current.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (show !== reader.result) {
          setShow(reader.result);
        }
      };
    }
  };

  useEffect(() => {
    if (show && setImage) {
      html2canvas(showRef.current, { logging: false }).then(canvas => {
        const data = canvas.toDataURL('image/jpeg');
        if (image !== data) {
          setImage(data);
        }
      });
    }
  }, [show, setImage]);

  return (
    <div className={`${className}`}>
      <button
        onClick={() => imgRef.current.click()}
        className="w-full mb-3 h-full flex place-content-center place-items-center"
      >
        {show && show !== '' ? (
          <img
            ref={showRef}
            className="object-fill w-full h-full"
            src={show}
            alt="preview"
          />
        ) : (
          <ArrowUpTrayIcon className="w-24 h-24 text-gray-300" />
        )}
      </button>
      <input
        className="hidden"
        accept=".jpg, .jpeg, .png"
        type="file"
        onChange={saveImgFile}
        ref={imgRef}
      />
    </div>
  );
};

export default ImageInput;
