import React, { useState } from 'react';
import HeaderTitle from 'component/Header/HeaderTitle';
import { useSelector } from 'react-redux';
import ReservationAPI from '../../api/reservationAPI';
import UserAPI from '../../api/userAPI';

const EmptyState = ({ message }) => (
  <div className="flex flex-col items-center justify-center py-16 px-4 bg-gray-50 rounded-xl">
    <img
      src="/imgs/graphic/graphic-empty.svg"
      alt="빈 상태"
      className="w-24 h-24 mb-4 opacity-50"
    />
    <p className="text-gray-500 text-center">{message}</p>
  </div>
);

const ReservationCard = ({ reservation, type }) => (
  <div className="bg-white p-5 rounded-xl shadow-sm border border-gray-100 hover:border-orange-200 transition-colors">
    <div className="flex justify-between items-start mb-3">
      <div>
        <h3 className="font-bold text-lg">{reservation.userName}</h3>
        <p className="text-sm text-gray-500">
          {new Date(reservation.startAt).toLocaleString('ko-KR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            weekday: 'long',
          })}
        </p>
      </div>
      <span
        className={`px-3 py-1 rounded-full text-sm ${
          type === 'reservation'
            ? 'bg-green-100 text-green-800'
            : 'bg-yellow-100 text-yellow-800'
        }`}
      >
        {type === 'reservation' ? '예약 완료' : '취소 요청'}
      </span>
    </div>
    {type === 'cancel' && (
      <>
        <div className="bg-gray-50 p-3 rounded-lg mb-4">
          <p className="text-gray-600 text-sm">{reservation.reason}</p>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => handleAcceptCancel(reservation._id, true)}
            className="flex-1 py-2.5 bg-orange-600 text-white rounded-lg hover:bg-orange-700 transition-colors font-medium"
          >
            수락
          </button>
          <button
            onClick={() => handleAcceptCancel(reservation._id, false)}
            className="flex-1 py-2.5 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-colors font-medium"
          >
            거절
          </button>
        </div>
      </>
    )}
  </div>
);

const GymManage = () => {
  const { accessToken } = useSelector(state => state.userAuth);
  const [selectedTab, setSelectedTab] = useState('reservations');

  // meData 쿼리
  const { data: meData, isLoading: isMeLoading } = UserAPI.useGetMe({
    accessToken,
  });
  const gymId = meData?.data?.masterGyms?.[0]?._id;

  // gymId가 있을 때만 예약 관련 쿼리 실행
  const { data: reservationsData } = ReservationAPI.useGetGymReservations({
    accessToken,
    gymId,
    master: true,
    enabled: !!gymId, // gymId가 있을 때만 실행
  });

  const { data: cancelReservations } = ReservationAPI.useGetCancelReservations({
    accessToken,
    enabled: !!gymId, // gymId가 있을 때만 실행
  });

  // 로딩 중이거나 체육관 ID가 없는 경우 처리
  if (isMeLoading) {
    return (
      <>
        <HeaderTitle title="예약 관리" />
        <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0">
          <div className="flex items-center justify-center h-40">
            <p className="text-gray-500">로딩 중...</p>
          </div>
        </div>
      </>
    );
  }

  if (!gymId) {
    return (
      <>
        <HeaderTitle title="예약 관리" />
        <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0">
          <div className="flex items-center justify-center h-40">
            <p className="text-gray-500">관리할 수 있는 체육관이 없습니다.</p>
          </div>
        </div>
      </>
    );
  }

  const reservations = reservationsData?.data?.data || [];
  const cancelRequests = cancelReservations?.data?.data || [];

  return (
    <>
      <HeaderTitle title="예약 관리" />
      <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0">
        <div className="flex gap-2 mt-4 mb-6">
          <button
            className={`flex-1 py-3.5 rounded-xl text-sm font-medium transition-colors ${
              selectedTab === 'reservations'
                ? 'bg-orange-600 text-white shadow-sm'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
            onClick={() => setSelectedTab('reservations')}
          >
            예약 목록
            {reservations.length > 0 && (
              <span className="ml-2 px-2 py-0.5 bg-white/20 rounded-full text-xs">
                {reservations.length}
              </span>
            )}
          </button>
          <button
            className={`flex-1 py-3.5 rounded-xl text-sm font-medium transition-colors ${
              selectedTab === 'cancels'
                ? 'bg-orange-600 text-white shadow-sm'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
            onClick={() => setSelectedTab('cancels')}
          >
            취소 요청
            {cancelRequests.length > 0 && (
              <span className="ml-2 px-2 py-0.5 bg-white/20 rounded-full text-xs">
                {cancelRequests.length}
              </span>
            )}
          </button>
        </div>

        <div className="space-y-4">
          {selectedTab === 'reservations' ? (
            reservations.length > 0 ? (
              reservations.map(reservation => (
                <ReservationCard
                  key={reservation._id}
                  reservation={reservation}
                  type="reservation"
                />
              ))
            ) : (
              <EmptyState message="아직 예약이 없습니다." />
            )
          ) : cancelRequests.length > 0 ? (
            cancelRequests.map(reservation => (
              <ReservationCard
                key={reservation._id}
                reservation={reservation}
                type="cancel"
              />
            ))
          ) : (
            <EmptyState message="취소 요청이 없습니다." />
          )}
        </div>
      </div>
    </>
  );
};

export default GymManage;
