import React from 'react';

const PeopleSelector = ({ reservation, setReservation }) => {
  return (
    <div className="w-full mt-5">
      <div className="flex items-center mb-3">
        <img src="/imgs/user.png" alt="인원 아이콘" className="w-6 h-6 mr-2" />
        <p className="text-xl font-bold">인원을 선택해 주세요.</p>
      </div>

      <div className="flex items-center justify-between border rounded-lg w-full px-4 py-2">
        <p className="text-xl">{reservation.peopleNum}명</p>

        <div className="flex items-center ml-auto">
          <div className="h-6 border-l border-gray-300 mx-2" />
          <button
            onClick={() =>
              setReservation(prev => ({
                ...prev,
                peopleNum: prev.peopleNum > 1 ? prev.peopleNum - 1 : 1,
              }))
            }
            className="px-2"
          >
            <img
              src="/imgs/downArrow.png"
              alt="인원 감소"
              className="w-6 h-6"
            />
          </button>

          <div className="h-6 border-l border-gray-300 mx-2" />

          <button
            onClick={() =>
              setReservation(prev => ({
                ...prev,
                peopleNum: prev.peopleNum + 1,
              }))
            }
            className="px-2"
          >
            <img src="/imgs/upArrow.png" alt="인원 증가" className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PeopleSelector;
