import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from 'page/Main/Main';
import Info from 'page/Info/Info';
import BaseLayout from '../layout/BaseLayout/BaseLayout';
import Reservation from 'page/Reservation/Reservation';
import Login from 'page/Login/Login';
import MyPage from 'page/MyPage/MyPage';
import AdminRoute from './AdminRoute';
import MyReservation from 'page/MyReservation/MyReservation';
import Notice from 'page/Notice/Notice';
import ChargeCash from '../page/MyPage/ChargeCash';
import CashHistory from '../page/MyPage/CashHistory';
import GymManage from '../page/MyPage/GymManage';

const AppRoute = () => {
  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Main />} />
          <Route path="info/:id" element={<Info />} />
          <Route path="complete" element={<div>complete</div>} />
          <Route path="myreservation" element={<MyReservation />} />
          <Route path="notice" element={<Notice />} />
          <Route path="mypage" element={<MyPage />} />
          <Route path="mypage/*">
            <Route path="chargecash" element={<ChargeCash />} />
            <Route path="cashhistory" element={<CashHistory />} />
            <Route path="gymmanage" element={<GymManage />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="*" element={<div>404</div>} />
        </Route>
        <Route path="reservation/:id" element={<Reservation />} />
        <Route path="login" element={<Login />} />
        <Route path="admin/*" element={<AdminRoute />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
