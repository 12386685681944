import React from 'react';
import SlideView from 'component/SlideView/SlideView';
import GymList from 'component/GymList/GymList';
import Header from 'component/Header/Header';
import { useSelector } from 'react-redux';
import UserAPI from '../../api/userAPI';

const SlideItem = ({ link, image, _id }) => {
  if (link)
    return (
      <a key={_id} href={link} className="w-full max-w-[40rem] m-auto flex">
        <img
          alt="slide item"
          src={image}
          className="w-full h-full object-contain"
        />
      </a>
    );
  else
    return (
      <div key={_id} className="w-full max-w-[40rem] m-auto flex">
        <img
          alt="slide item"
          src={image}
          className="w-full h-full object-contain"
        />
      </div>
    );
};

const Main = () => {
  const { accessToken } = useSelector(state => state.userAuth);
  const { data: bannerData } = UserAPI.useGetBanners({ accessToken });
  const defaultBanner = [
    {
      image: '/imgs/slide_1.png',
      _id: '1',
      link: '',
      order: 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
  ];

  return [
    <Header />,
    <div className="w-full mb-8">
      <SlideView renderer={SlideItem} data={bannerData ?? defaultBanner} />
    </div>,
    <GymList />,
  ];
};

export default Main;
