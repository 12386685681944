import React from 'react';
import { Link } from 'react-router-dom';
import useLoginCheck from '../../hooks/UseLoginCheck';
import userAPI from '../../api/userAPI';
import reservationAPI from '../../api/reservationAPI';
import { useSelector } from 'react-redux';

const StatusIcons = {
  wait: <img src="/imgs/ic/ic-wait.svg" alt="wait" className="w-4 h-4" />,
  end: <img src="/imgs/ic/ic-end.svg" alt="end" className="w-4 h-4" />,
  cancelled: (
    <img src="/imgs/ic/ic-cancelled.svg" alt="cancelled" className="w-4 h-4" />
  ),
  confirmed: (
    <img src="/imgs/ic/ic-confirmed.svg" alt="confirmed" className="w-4 h-4" />
  ),
  default: (
    <img src="/imgs/ic/ic-default.svg" alt="default" className="w-4 h-4" />
  ),
  calendar: (
    <img src="/imgs/ic/ic-calendar.svg" alt="calendar" className="w-4 h-4" />
  ),
  point: <img src="/imgs/ic/ic-point.svg" alt="point" className="w-4 h-4" />,
};

const MyReservation = () => {
  const nextLink = '/myreservation';
  useLoginCheck(false, nextLink);
  const { accessToken } = useSelector(state => state.userAuth);
  const { data: me } = userAPI.useGetMe({ accessToken });
  const requestCancel = reservationAPI.useRequestCancelReservation();

  // 현재 날짜 기준으로 예약을 현재/과거로 분류
  const now = new Date();
  const currentReservations =
    me?.data?.reservations?.filter(
      reservation =>
        new Date(reservation.startAt) > now && reservation.status !== 'end',
    ) || [];

  const pastReservations =
    me?.data?.reservations?.filter(
      reservation =>
        new Date(reservation.startAt) <= now || reservation.status === 'end',
    ) || [];

  // 날짜 포맷팅 함수
  const formatDate = dateString => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const dayOfWeek = ['일', '월', '화', '수', '목', '금', '토'][date.getDay()];

    return `${month}.${day} (${dayOfWeek}) ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  // 예약 상태 한글화 및 스타일링
  const getStatusInfo = status => {
    switch (status) {
      case 'wait':
        return {
          text: '예약 대기',
          color: 'text-yellow-500',
          icon: StatusIcons.wait,
        };
      case 'end':
        return {
          text: '이용 완료',
          color: 'text-gray-500',
          icon: StatusIcons.end,
        };
      case 'CANCELLED':
        return {
          text: '취소됨',
          color: 'text-red-500',
          icon: StatusIcons.cancelled,
        };
      case 'CONFIRMED':
        return {
          text: '예약 완료',
          color: 'text-green-500',
          icon: StatusIcons.confirmed,
        };
      default:
        return {
          text: '예약 처리중',
          color: 'text-blue-500',
          icon: StatusIcons.default,
        };
    }
  };

  const handleRequestCancel = async reservationId => {
    if (window.confirm('예약을 취소하시겠습니까?')) {
      try {
        await requestCancel.mutateAsync({
          accessToken,
          reservationId,
        });
        alert('예약이 취소되었습니다.');
      } catch (error) {
        alert('예약 취소에 실패했습니다.');
        console.error('예약 취소 실패:', error);
      }
    }
  };

  return (
    <div className="w-full max-w-[40rem] mx-auto p-5">
      <h1 className="text-xl font-bold mb-4">예약내역</h1>

      {/* 현재 예약 */}
      <h2 className="text-lg font-bold mb-2">현재 예약</h2>
      {currentReservations.length === 0 ? (
        <p className="text-gray-500 text-sm py-4">현재 예약이 없습니다.</p>
      ) : (
        currentReservations.map(reservation => (
          <div
            key={reservation._id}
            className="border-b border-gray-300 py-3 flex justify-between items-center"
          >
            <Link to={`/info/${reservation._id}`} className="flex-1">
              <h3 className="font-bold text-lg">{reservation.gymName}</h3>
              <p className="text-gray-500 text-sm flex items-center gap-2">
                <span className="text-gray-400 flex items-center justify-center w-4 h-4">
                  {StatusIcons.calendar}
                </span>
                {formatDate(reservation.startAt)}
              </p>
              <p className="text-gray-500 text-sm flex items-center gap-2">
                <span className="text-gray-400 flex items-center justify-center w-4 h-4">
                  {StatusIcons.point}
                </span>
                {reservation.point} 포인트
              </p>
            </Link>
            <div className="flex flex-col items-end gap-2">
              <p
                className={`text-sm font-bold flex items-center gap-1 ${getStatusInfo(reservation.status).color}`}
              >
                <span>{getStatusInfo(reservation.status).icon}</span>
                <span>{getStatusInfo(reservation.status).text}</span>
              </p>
              {reservation.status !== 'cancelled' && (
                <button
                  onClick={() => handleRequestCancel(reservation._id)}
                  className="px-3 py-1 text-sm text-white bg-red-500 rounded-lg hover:bg-red-600"
                >
                  예약 취소
                </button>
              )}
            </div>
          </div>
        ))
      )}

      {/* 지난 예약 */}
      <h2 className="text-lg font-bold mt-6 mb-2">지난 예약</h2>
      {pastReservations.length === 0 ? (
        <p className="text-gray-500 text-sm py-4">지난 예약이 없습니다.</p>
      ) : (
        pastReservations.map(reservation => (
          <div
            key={reservation._id}
            className="border-b border-gray-300 py-3 flex justify-between items-center"
          >
            <Link to={`/info/${reservation.gymId}`} className="flex-1">
              <h3 className="font-bold text-lg">{reservation.gymName}</h3>
              <p className="text-gray-500 text-sm flex items-center gap-2">
                <span className="text-gray-400 flex items-center justify-center w-4 h-4">
                  {StatusIcons.calendar}
                </span>
                {formatDate(reservation.startAt)}
              </p>
              <p className="text-gray-500 text-sm flex items-center gap-2">
                <span className="text-gray-400 flex items-center justify-center w-4 h-4">
                  {StatusIcons.point}
                </span>
                {reservation.point} 포인트
              </p>
            </Link>
            <p
              className={`text-sm ${getStatusInfo(reservation.status).color} flex items-center gap-1`}
            >
              <span>{getStatusInfo(reservation.status).icon}</span>
              <span>{getStatusInfo(reservation.status).text}</span>
            </p>
          </div>
        ))
      )}
    </div>
  );
};

export default MyReservation;
