import { API } from 'api/API';
import EditGymLayout from 'layout/Admin/EditGymLayout';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CreatableSelector from 'component/Admin/CreatableSelector';
import {
  classGymValidator,
  freeGymValidator,
  validateChecker,
} from 'utils/validator';
import { getPosFromAddress } from 'utils/mapHelper';
import regionList from 'data/regionList';
import Selector from 'component/Admin/Selector';
import GymType from 'data/Admin/GymType';
import { useSelector } from 'react-redux';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

const dayName = ['월', '화', '수', '목', '금', '토', '일'];

const EditGymInfo = () => {
  const { accessToken } = useSelector(state => state.adminAuth);
  const { gymid } = useParams();
  const navigate = useNavigate();
  const [gym, setGym] = useState({
    type: GymType[0],
    category: null,
    latitude: null,
    longitude: null,
    address: '',
    region: null,
    city: null,
    name: '',
    description: '',
    phoneNumber: '',
    master: null,
    manager: null,
    supplies: '',
    dayPoint: 0,
    gymFee: 0,
    officialHoliday: false,
    openTime: '00:00',
    closeTime: '23:59',
    openDay: [
      {
        activate: false,
        dayOfWeek: 0,
        openTime: '00:00',
        closeTime: '00:00',
      },
      {
        activate: false,
        dayOfWeek: 1,
        openTime: '00:00',
        closeTime: '00:00',
      },
      {
        activate: false,
        dayOfWeek: 2,
        openTime: '00:00',
        closeTime: '00:00',
      },
      {
        activate: false,
        dayOfWeek: 3,
        openTime: '00:00',
        closeTime: '00:00',
      },
      {
        activate: false,
        dayOfWeek: 4,
        openTime: '00:00',
        closeTime: '00:00',
      },
      {
        activate: false,
        dayOfWeek: 5,
        openTime: '00:00',
        closeTime: '00:00',
      },
      {
        activate: false,
        dayOfWeek: 6,
        openTime: '00:00',
        closeTime: '00:00',
      },
    ],
  });

  const { mutateAsync: updateGymInfo } = API.useUpdateGymInfo();
  const { mutateAsync: createCity } = API.useCreateCity();
  const { mutateAsync: createGymCategory } = API.useCreateGymCategory();

  const { data: cityData, refetch: cityRefetch } = API.useGetCities({
    regionCode: gym.region?.value,
  });
  const { data: categoryData, refetch: categoryRefetch } =
    API.useGetGymCategories();

  const { data: userData } = API.useGetUsers({ accessToken });

  const { data: gymData } = API.useGetGymDetails({ gymId: gymid });

  useEffect(() => {
    if (gymData) {
      if (!gymData?.success) {
        alert('잘못된 접근입니다.');
        navigate(-1);
      } else {
        const daysOfWeek = Array(7)
          .fill()
          .map((_, index) => ({
            dayOfWeek: index,
            openTime: '00:00',
            closeTime: '23:59',
            activate: false,
          }));

        const mergedOpenDays = daysOfWeek.map(day => {
          const dbDay = (gymData.data.openDays || []).find(
            d => d.dayOfWeek === day.dayOfWeek,
          );

          return {
            ...day,
            openTime: dbDay?.openTime || day.openTime,
            closeTime: dbDay?.closeTime || day.closeTime,
            activate: !!dbDay, // DB에 값이 있으면 activate=true
          };
        });

        setGym({
          type: GymType.find(v => v.value === gymData.data.type),
          category: {
            value: gymData.data.category._id,
            label: gymData.data.category.name,
          },
          latitude: gymData.data.latitude,
          longitude: gymData.data.longitude,
          address: gymData.data.address,
          region: {
            value: gymData.data.city.regionCode,
            label: gymData.data.city.regionCode,
          },
          city: {
            value: gymData.data.city._id,
            label: gymData.data.city.name,
          },
          name: gymData.data.name,
          description: gymData.data.description,
          phoneNumber: gymData.data.phoneNumber,
          master: {
            value: gymData.data.master._id,
            label: `${gymData.data.master.name}(${gymData.data.master._id})`,
          },
          manager: gymData.data.manager
            ? {
                value: gymData.data.manager._id,
                label: `${gymData.data.manager.name}(${gymData.data.manager._id})`,
              }
            : null,
          supplies: gymData.data.supplies,
          dayPoint: gymData.data.dayPoint,
          gymFee: gymData.data.gymFee,
          officialHoliday: gymData.data.officialHoliday,
          openTime: gymData.data.openTime,
          closeTime: gymData.data.closeTime,
          openDay: mergedOpenDays,
        });
      }
    }
  }, [gymData, navigate]);

  const onSubmit = () => {
    if (!gym.type) {
      alert('체육관 타입을 선택해주세요.');
      return;
    } else if (gym.type.value === 'free') {
      const result = validateChecker(gym, freeGymValidator);
      if (!result.success) {
        alert(result.message);
        return;
      }
    } else {
      const result = validateChecker(gym, classGymValidator);
      if (!result.success) {
        alert(result.message);
        return;
      }
    }

    const updatedOpenDay = gym.openDay
      .map(v => ({
        dayOfWeek: v.dayOfWeek,
        openTime: v.activate ? v.openTime || '00:00' : '00:00',
        closeTime: v.activate ? v.closeTime || '23:59' : '23:59',
      }))
      .filter(v => v.activate && v.openTime && v.closeTime); // openTime, closeTime이 비어 있지 않도록 필터링

    updateGymInfo({
      gymId: gymid,
      accessToken,
      type: gym.type.value,
      categoryId: gym.category.value,
      latitude: parseFloat(gym.latitude),
      longitude: parseFloat(gym.longitude),
      address: gym.address,
      cityId: gym.city.value,
      name: gym.name,
      description: gym.description,
      phoneNumber: gym.phoneNumber,
      master: gym.master.value,
      supplies: gym.supplies,
      dayPoint: parseInt(gym.dayPoint),
      gymFee: parseInt(gym.gymFee),
      officialHoliday: gym.officialHoliday,
      openTime: '00:00',
      closeTime: '23:59',
      openDay: updatedOpenDay,
    }).then(res => {
      if (res.success) {
        alert('체육관 정보가 수정되었습니다.');
        navigate(-1);
      } else {
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      }
    });
  };

  const onAddressChange = async text => {
    setGym(prev => ({ ...prev, address: text }));
    getPosFromAddress(text, (lat, lng) => {
      setGym(prev => ({
        ...prev,
        latitude: lat,
        longitude: lng,
      }));
    });
  };

  if (!gymData?.success) return null;
  return (
    <EditGymLayout gym={gymData.data}>
      <div className="p-10 flex flex-col">
        <div className="h-20 w-full flex flex-row">
          <p className="text-3xl font-semibold text-gray-500">
            * 체육관 정보 수정
          </p>
        </div>
        <div className="flex w-full flex-col 2xl:flex-row">
          <div className="grid grid-cols-2 w-full 2xl:w-1/2 2xl:pr-10 gap-y-5 gap-x-20">
            <div className="w-full flex flex-col col-span-2">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 상호명
              </p>
              <input
                value={gym.name}
                onChange={e =>
                  setGym(prev => ({ ...prev, name: e.target.value }))
                }
                className="border-2 text-xl bg-transparent border-gray-300 p-2 mb-2"
              />
            </div>
            <div className="w-full flex flex-col col-span-2">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 체육관 전화번호
              </p>
              <input
                value={gym.phoneNumber}
                onChange={e =>
                  setGym(prev => ({ ...prev, phoneNumber: e.target.value }))
                }
                className="border-2 text-xl bg-transparent border-gray-300 p-2 mb-2"
              />
            </div>
            <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 지역
              </p>
              <Selector
                value={gym.region}
                items={regionList.map(v => ({
                  value: v.label,
                  label: v.label,
                }))}
                onSelect={v => {
                  setGym(prev => ({ ...prev, region: v, city: null }));
                }}
              />
            </div>
            <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">- 구</p>
              <CreatableSelector
                disabled={!gym.region}
                value={gym.city}
                items={cityData?.data?.cities?.map(v => ({
                  value: v._id,
                  label: v.name,
                }))}
                onSelect={v => {
                  setGym(prev => ({ ...prev, city: v }));
                }}
                onCreate={v => {
                  createCity({
                    accessToken,
                    regionCode: gym.region.value,
                    name: v,
                  }).then(res => {
                    setGym(prev => ({
                      ...prev,
                      city: {
                        value: res.data._id,
                        label: v,
                      },
                    }));
                    cityRefetch();
                  });
                }}
              />
            </div>
            {/* <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 타입
              </p>
              <Selector
                value={gym?.type}
                items={GymType}
                onSelect={(v) => setGym((prev) => ({ ...prev, type: v }))}
              />
            </div> */}
            <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 카테고리
              </p>
              <CreatableSelector
                value={gym.category}
                items={categoryData?.data?.categories?.map(v => ({
                  value: v._id,
                  label: v.name,
                }))}
                onSelect={v => {
                  setGym(prev => ({ ...prev, category: v }));
                }}
                onCreate={v => {
                  createGymCategory({ accessToken, name: v }).then(res => {
                    setGym(prev => ({
                      ...prev,
                      category: {
                        value: res.data._id,
                        label: v,
                      },
                    }));
                    categoryRefetch();
                  });
                }}
              />
            </div>
            <div className="w-full flex flex-col col-span-2">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 상세주소
              </p>
              <input
                value={gym?.address}
                onChange={e => onAddressChange(e.target.value)}
                className="border-2 text-xl bg-transparent border-gray-300 p-2 mb-2"
              />
              <details>
                <summary className="text-xl text-gray-500 font-semibold mb-2">
                  지도
                </summary>
                <Map
                  center={{
                    lat: gym?.latitude,
                    lng: gym?.longitude,
                  }}
                  className="w-full h-[30rem] bg-gray-300"
                >
                  <MapMarker
                    position={{
                      lat: gym?.latitude,
                      lng: gym?.longitude,
                    }}
                  />
                </Map>
              </details>
            </div>
            <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 대표자
              </p>
              <Selector
                value={gym?.master}
                items={userData?.data?.map?.(v => ({
                  value: v._id,
                  label: `${v.name}(${v._id})`,
                }))}
                onSelect={v => {
                  setGym(prev => ({ ...prev, master: v }));
                }}
              />
            </div>
            <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 부대표자
              </p>
              <Selector
                value={gym?.manager}
                items={userData?.data
                  ?.filter?.(v => v._id !== gym?.master?.value)
                  ?.map?.(v => ({
                    value: v._id,
                    label: `${v.name}(${v._id})`,
                  }))}
                onSelect={v => {
                  setGym(prev => ({ ...prev, manager: v }));
                }}
              />
            </div>
            {/* <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 공휴일 휴무
              </p>
              <div className="grid grid-cols-2 gap-x-5">
                <button
                  onClick={() => {
                    setGym((prev) => ({
                      ...prev,
                      officialHoliday: !prev.officialHoliday,
                    }));
                  }}
                  className={`text-xl border-2 rounded-xl ${
                    gym.officialHoliday
                      ? "text-skyblue2 border-skyblue2"
                      : "text-gray-300 border-gray-300"
                  }`}
                >
                  휴무
                </button>
                <button
                  onClick={() => {
                    setGym((prev) => ({
                      ...prev,
                      officialHoliday: !prev.officialHoliday,
                    }));
                  }}
                  className={`text-xl border-2 rounded-xl ${
                    !gym.officialHoliday
                      ? "text-skyblue2 border-skyblue2"
                      : "text-gray-300 border-gray-300"
                  }`}
                >
                  영업
                </button>
              </div>
            </div> */}
            <div className="w-full flex flex-col col-span-2 gap-y-5">
              <div className="w-full grid grid-flow-col grid-cols-7 gap-x-10">
                <p className="text-2xl w-20 text-gray-500 font-semibold mb-2">
                  - 요일
                </p>
                <p className="w-full col-span-3 text-2xl text-gray-500 font-semibold mb-2">
                  - 문여는 시간
                </p>
                <p className="w-full col-span-3 text-2xl text-gray-500 font-semibold mb-2">
                  - 문닫는 시간
                </p>
              </div>
              {dayName.map((v, i) => (
                <div className="w-full grid grid-flow-col grid-cols-7 gap-x-10">
                  <button
                    onClick={() => {
                      setGym(prev => {
                        const openDay = [...prev.openDay];
                        openDay[i] = {
                          ...openDay[i],
                          activate: !openDay[i].activate,
                        }; // Ensure proper update
                        return { ...prev, openDay };
                      });
                    }}
                    key={i}
                    className={`text-xl border-2 rounded-xl w-20 h-full mb-2 ${gym.openDay[i].activate ? 'text-skyblue2 border-skyblue2' : 'text-gray-300 border-gray-300'}`}
                  >
                    {v}
                  </button>
                  <input
                    disabled={gym.openDay[i]?.activate === false}
                    value={gym.openDay[i]?.openTime || ''}
                    onChange={e => {
                      setGym(prev => {
                        const newOpenDay = [...prev.openDay];
                        newOpenDay[i] = {
                          ...newOpenDay[i],
                          openTime: e.target.value,
                        };
                        return { ...prev, openDay: newOpenDay };
                      });
                    }}
                    type="time"
                    className="border-2 w-full text-xl col-span-3 bg-transparent border-gray-300 p-2 mb-2"
                  />
                  <input
                    disabled={gym.openDay[i]?.activate === false}
                    value={gym.openDay[i]?.closeTime || ''}
                    onChange={e => {
                      setGym(prev => {
                        const newOpenDay = [...prev.openDay];
                        newOpenDay[i] = {
                          ...newOpenDay[i],
                          closeTime: e.target.value,
                        };
                        return { ...prev, openDay: newOpenDay };
                      });
                    }}
                    type="time"
                    className="border-2 text-xl w-full col-span-3 bg-transparent border-gray-300 p-2 mb-2"
                  />
                </div>
              ))}
            </div>

            <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 1인 1회당 가격
              </p>
              <input
                value={gym.dayPoint}
                onChange={e => {
                  setGym(prev => ({
                    ...prev,
                    dayPoint: e.target.value > 0 ? e.target.value : 0,
                  }));
                }}
                type="number"
                className="border-2 text-xl bg-transparent border-gray-300 p-2 mb-2"
              />
            </div>
            <div className="w-full flex flex-col">
              <p className="text-2xl text-gray-500 font-semibold mb-2">
                - 1인 1회당 수수료
              </p>
              <input
                value={gym.gymFee}
                onChange={e => {
                  setGym(prev => ({
                    ...prev,
                    gymFee: e.target.value > 0 ? e.target.value : 0,
                  }));
                }}
                type="number"
                className="border-2 text-xl bg-transparent border-gray-300 p-2 mb-2"
              />
            </div>
          </div>
          <div className="w-full 2xl:w-1/2 h-full flex flex-col">
            <p className="text-2xl text-gray-500 font-semibold mb-2">- 설명</p>
            <textarea
              value={gym.description}
              onChange={e => {
                setGym(prev => ({ ...prev, description: e.target.value }));
              }}
              className="w-full h-[20rem] border-2 text-xl bg-transparent border-gray-300 p-2 mb-2 resize-none"
            />
            <p className="text-2xl text-gray-500 font-semibold mb-2 mt-10">
              - 준비물
            </p>
            <textarea
              value={gym.supplies}
              onChange={e => {
                setGym(prev => ({ ...prev, supplies: e.target.value }));
              }}
              className="w-full h-[20rem] border-2 text-xl bg-transparent border-gray-300 p-2 mb-2 resize-none"
            />
            <p className="text-2xl text-gray-500 font-semibold mb-2">
              - 상세주소
            </p>
            <input
              value={gym?.address}
              onChange={e => onAddressChange(e.target.value)}
              className="border-2 text-xl bg-transparent border-gray-300 p-2 mb-2"
            />
            <p className="text-xl text-gray-500 font-semibold mb-2">지도</p>
            <Map
              center={{
                lat: gym?.latitude,
                lng: gym?.longitude,
              }}
              className="w-full h-[30rem] bg-gray-300"
            >
              <MapMarker
                position={{
                  lat: gym?.latitude,
                  lng: gym?.longitude,
                }}
              />
            </Map>
            <button
              onClick={onSubmit}
              className="bg-green-300 text-green-600 text-2xl px-5 py-2 mt-auto ml-auto"
            >
              수정
            </button>
          </div>
        </div>
      </div>
    </EditGymLayout>
  );
};

export default EditGymInfo;
